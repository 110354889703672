<template>
  <b-overlay
    :show="mostrarCargando"
    spinner-variant="primary"
    variant="semi-dark"
    v-if="$can('update', 'cursos')"
  >
    <b-card
      title=" "
      aria-hidden="true"
      class="mb-0"
    >
      <b-row>
        <b-col
          lg="6"
          md="6"
          sm="6"
          class="mb-50"
        >
          <!-- BOTON Titulo -->
          <h3 class="text-primary mb-50 mt-0">
            {{ selectedCurso.nombreGrado }} {{ selectedCurso.letra }}
          </h3>
          <p >Total estudiantes: {{ getAlumnosCurso.length }}</p>
        </b-col>
        <b-col
          md="6"
          sm="6"
          class="mt-0"
        >
          <!-- BOTON Actualizar -->
          <!-- <btnSubmit
            class=""
            variant="primary"
            :modulo="nombre_permiso"
            :btnText="btnSubmit"
            @processBtn="submitOption"
          /> -->
        </b-col>
      </b-row>
      <!-- draggable -->
      <draggable
        v-model="item"
        class="list-group list-group-flush cursor-move"
        tag="ul"
      >
        <transition-group
          type="transition"
          name="flip-list"
        >

          <b-list-group-item
            v-for="(listItem, index) in item"
            :key="index"
            tag="li"
          >
              <!-- <b-avatar :text="listItem.name.slice(0,2)" /> -->
            <div class="d-flex">
              <div class="ml-25">
                <b-card-text class="font-weight-bold mb-0">
                  {{ listItem.numLista }} - {{ listItem.nombres }} {{ listItem.primerApellido }} {{ listItem.segundoApellido }}
                </b-card-text>
                <small>{{ formatRut(listItem.rut) }}</small>
              </div>
            </div>
          </b-list-group-item>
        </transition-group>
      </draggable>
      <colLinea />

        <!-- v-if="mostrarCargando" -->
      <btnSubmit
        variant="primary"
        :btnText="btnSubmit"
        :modulo="nombre_permiso"
        @processBtn="submitList()"
      />

    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BListGroupItem, BCardText, BRow, BCol, BCard,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'

import { mapGetters, mapActions, mapState } from 'vuex'
import { rut } from '@core/mixins/ui/rut'

import btnSubmit from '../../components/Form/btnSubmit.vue'
import colLinea from '../../components/Form/colLinea.vue'

export default {
  components: {
    BCardText,
    BOverlay,
    draggable,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    btnSubmit,
    colLinea,
  },
  mixins: [rut],
  data() {
    return {
      nombre_permiso: 'lista',
      mostrarCargando: true,
      btnSubmit: 'Actualizar orden de Lista',
      item: [],
    }
  },
  computed: {
    ...mapState('cursos', ['selectedCurso']),
    ...mapGetters({
      getAlumnosCurso: 'alumnos/getAlumnosCurso',
      // user: 'auth/user',
    }),
  },
  watch: {
    getAlumnosCurso(val) {
      this.item = val
      this.mostrarCargando = false
    },
  },
  mounted() {
    this.cargaEstudiantes(this.selectedCurso.id)
  },
  methods: {
    ...mapActions({
      fetchAlumnosCurso: 'alumnos/fetchAlumnosCurso',
      ordenarLista: 'cursos/ordenarLista',
    }),
    cargaEstudiantes(idCurso) {
      this.item = []
      this.mostrarCargando = true
      this.fetchAlumnosCurso(idCurso).then(() => {})
    },
    submitList() {
      const lista = []
      let i = 0
      this.item.forEach(item => {
        /* eslint-disable no-plusplus */
        i++
        lista.push({
          id: item.id,
          orden: i,
        })
      })

      const data = {
        idCurso: this.selectedCurso.id,
        lista,
      }
      this.ordenarLista(data).then(() => {
        this.cargaEstudiantes(this.selectedCurso.id)
      })
    },
  },
}

</script>

<style>
.list-group-item {
  transition: all 1s
}
</style>
